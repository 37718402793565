import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/api/hero"
import Process from "components/sections/api/process"
import Features from "components/sections/api/features"
import Workflows from "components/sections/corporates/workflows"
import Banner from "components/sections/common/banner"

const ApiPage = ({ data }) => {
  const { frontmatter } = data.api.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
     <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Process content={data.process.edges} />
        <Workflows content={data.flows.edges} />
        <Features content={data.apis.edges} />
        <Banner content={data.banner.edges} />
        <div className="gradient">

        </div>
        
      </Layout>
    </GlobalStateProvider>
  )
}


export default ApiPage

export const pageQuery = graphql`
  {
    api: allMdx(filter: { fileAbsolutePath: { regex: "/api/fr/api/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/api/fr/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            description
            teamPhoto {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    }
    process: allMdx(filter: { fileAbsolutePath: { regex: "/api/fr/process/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            processes {
              name
              description
              icon
            }
          }
        }
      }
    }
    flows: allMdx(filter: { fileAbsolutePath: { regex: "/api/fr/workflows/" } }) {
      edges {
        node {
          frontmatter {
            titlePrefix1
            title1
            title2
            titlePrefix2
            title3
            titlePrefix3
            sourcesDescription
            destinationsDescription
            leftTitle
            rightTitle
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
    apis: allMdx(filter: { fileAbsolutePath: { regex: "/api/fr/apis/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
          }
          exports {
            items {
              name
              title
              subtitle
              description
              animation
              reversed
              buttons {
                text
                link
                type
                external
              }
            }
          }
        }
      }
    }
    banner: allMdx(filter: { fileAbsolutePath: { regex: "/api/fr/banner/" } }) {
      edges {
        node {
          frontmatter {
            title
            buttonTitle
            link
            external
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
